import React, { useRef, useEffect, useCallback } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Tag,
  useToast,
  VStack,
  Text,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { FiPlus, FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import { CategoryUpsertAlert } from "./category/categoryUpsert";
import { QuestionList } from "./question/questionList";
import { QuestionUpsertAlert } from "./question/questionUpsert";
import { useUiContext } from "../../../contexts/UiContext";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import { useApi } from "../../../contexts/ApiContext";

export function FormBuilderPage() {
  const toast = useToast();
  const { showAlert, closeAlertBox, showModal, closeModal } = useUiContext();
  let { id: projectId } = useParams();
  const { data: questionForm, mutate } = useSWR(
    `/projects/${projectId}/questions`
  );
  const { data: project } = useSWR(`/projects/${projectId}`);
  const { formApi } = useApi();

  const categoryRefs = useRef({});

  useEffect(() => {
    if (questionForm) {
      questionForm.nestedCategories.forEach((mainCat) => {
        categoryRefs.current[mainCat._id] = React.createRef();
        mainCat.subCategories?.forEach((subCat) => {
          categoryRefs.current[subCat._id] = React.createRef();
        });
      });
    }
  }, [questionForm]);

  const scrollToCategory = useCallback((categoryId) => {
    const ref = categoryRefs.current[categoryId];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  function addCategory(mainCategoryId) {
    showAlert(
      <CategoryUpsertAlert
        projectId={projectId}
        mainCategoryId={mainCategoryId}
        onClose={closeAlertBox}
        mutateQuestionForm={mutate}
      />
    );
  }

  function editCategory({ _id, name, relatedGoals }) {
    showAlert(
      <CategoryUpsertAlert
        projectId={projectId}
        category={{ _id, name, relatedGoals: relatedGoals.map((g) => g._id) }}
        onClose={closeAlertBox}
        mutateQuestionForm={mutate}
      />
    );
  }

  async function deleteCategory(categoryId) {
    try {
      await formApi.deleteCategory(categoryId);
      toast({
        title: "Başarılı",
        description: "Kategori silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutate();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  function addQuestion(categoryId) {
    showModal(
      <QuestionUpsertAlert
        projectId={projectId}
        categoryId={categoryId}
        onClose={closeModal}
        mutateQuestionForm={mutate}
      />
    );
  }

  const CategoryButton = ({ category, isSubCategory = false }) => (
    <Button
      size="sm"
      variant="ghost"
      justifyContent="space-between"
      onClick={() => scrollToCategory(category._id)}
      _hover={{ bg: "gray.100" }}
      width="100%"
      pl={isSubCategory ? 6 : 3}
    >
      <Text isTruncated>{category.name}</Text>
      <Badge ml={2} colorScheme="gray">
        {`${category.maxScore || 'X'}/${category.maxPersonnelScore || 'X'}`}
      </Badge>
    </Button>
  );

  if (!questionForm) {
    return <LoadingIndicator />;
  }

  return (
    <PageLayout>
      <PageHeader title="Proje Soru Formu">
        {!project?.isArchived && (
          <Button
            rightIcon={<FiPlus />}
            size="sm"
            variant="outline"
            colorScheme="teal"
            onClick={() => addCategory(null)}
          >
            Ana Kategori
          </Button>
        )}
      </PageHeader>
     
      {questionForm.nestedCategories.length > 0 ? (

      <Flex>
        <Box flex="1" pr={4}>
          {questionForm &&
            questionForm.nestedCategories.map((mainCat) => (
              <Box key={mainCat._id} ref={categoryRefs.current[mainCat._id]}>
                <Card bg={"gray.200"} topMargin>
                  {/* Main Category Header */}
                  <Box bg="gray.300" borderRadius="xl" py={4} px={8}>
                    <PageHeader
                      color="gray.500"
                      size="md"
                      title={mainCat.name}
                    >
                      <div>
                        <CategoryMaxScores category={mainCat} />
                      </div>
                      <EditCategoryButton onClick={() => editCategory(mainCat)} />
                      {mainCat.questions.length === 0 &&
                        mainCat.subCategories.length === 0 && (
                          <DeleteCategoryButton
                            showAlert={showAlert}
                            closeAlertBox={closeAlertBox}
                            onClick={() => deleteCategory(mainCat._id)}
                          />
                        )}
                      <Button
                        rightIcon={<FiPlus />}
                        size="sm"
                        variant="outline"
                        colorScheme={"blackAlpha"}
                        onClick={() => addCategory(mainCat._id)}
                      >
                        Alt Kategori
                      </Button>
                      <AddQuestionButton
                        onClick={() => addQuestion(mainCat._id)}
                      />
                    </PageHeader>
                    {mainCat.relatedGoals && <CategoryGoals category={mainCat} />}
                  </Box>

                  {/* Main Category Questions */}
                  <QuestionList
                    questions={mainCat.questions}
                    mutateQuestionForm={mutate}
                    formQuestions={questionForm.questionList}
                  />

                  {mainCat.subCategories?.map((subCat) => (
                    <Box key={subCat._id} ref={categoryRefs.current[subCat._id]}>
                      <Card topMargin>
                        <Box bg="white" borderRadius="xl" py={4} px={8}>
                          <PageHeader
                            color="gray.500"
                            size="md"
                            title={subCat.name}
                          >
                            <div>
                              <CategoryMaxScores category={subCat} sub />
                            </div>
                            <EditCategoryButton
                              onClick={() => editCategory(subCat)}
                            />
                            {subCat.questions.length === 0 && (
                              <DeleteCategoryButton
                                showAlert={showAlert}
                                closeAlertBox={closeAlertBox}
                                onClick={() => deleteCategory(subCat._id)}
                              />
                            )}

                            <AddQuestionButton
                              onClick={() => addQuestion(subCat._id)}
                            />
                          </PageHeader>
                          {subCat.relatedGoals && (
                            <CategoryGoals category={subCat} sub />
                          )}
                        </Box>

                        {/* Sub_Category Questions */}
                        <QuestionList
                          questions={subCat.questions}
                          mutateQuestionForm={mutate}
                          formQuestions={questionForm.questionList}
                        />
                      </Card>
                    </Box>
                  ))}
                </Card>
              </Box>
            ))}
        </Box>
        <Box width="250px" position="sticky" top="20px" alignSelf="flex-start">
          <VStack align="stretch" spacing={2}>
            <Text fontWeight="bold" mb={2}>
              Kategoriler
            </Text>
            {questionForm?.nestedCategories.map((mainCategory) => (
              <VStack key={mainCategory._id} align="stretch" spacing={1}>
                <CategoryButton category={mainCategory} />
                {mainCategory.subCategories?.map((subCategory) => (
                  <CategoryButton 
                    key={subCategory._id} 
                    category={subCategory} 
                    isSubCategory 
                  />
                ))}
              </VStack>
            ))}
          </VStack>
        </Box>
      </Flex>
      ):(
        <Box textAlign="center" py={10}>
        <Text fontSize="lg" color="gray.600">
          Başlamak için ana kategori ekleyin
        </Text>
      </Box>
      )}
     
    </PageLayout>
  );
}

function EditCategoryButton({ onClick }) {
  return (
    <IconButton
      aria-label="Kategori düzenle"
      size="sm"
      variant="outline"
      colorScheme={"blackAlpha"}
      icon={<EditIcon />}
      onClick={onClick}
    />
  );
}

function DeleteCategoryButton({ showAlert, closeAlertBox, onClick }) {
  return (
    <IconButton
      aria-label="Kategori sil"
      size="sm"
      variant="outline"
      colorScheme={"blackAlpha"}
      icon={<FiX />}
      onClick={() => {
        showAlert(<DeleteConfirmAlert name={"Kategori"} onDelete={onClick} />);
      }}
    />
  );
}

function AddQuestionButton({ onClick }) {
  return (
    <Button
      rightIcon={<FiPlus />}
      size="sm"
      variant="outline"
      colorScheme={"blackAlpha"}
      onClick={onClick}
    >
      Soru
    </Button>
  );
}

function CategoryGoals({ category, sub }) {
  return (
    <Flex wrap={"wrap"} marginTop={1} marginLeft={sub && 4}>
      {category.relatedGoals.map((goal) => (
        <Badge
          key={goal._id}
          marginTop={2}
          marginRight={2}
          variant="solid"
          px={2}
          py={1}
          colorScheme="blue"
          // fontSize={"sm"}
        >
          {goal.name}
        </Badge>
      ))}
    </Flex>
  );
}

function CategoryMaxScores({ category, sub }) {
  const { maxScore, maxPersonnelScore } = category;
  return (
    <Badge fontSize={"sm"} marginLeft={sub && 4} colorScheme="gray ">
      {`${maxScore || "X"} / ${maxPersonnelScore || "X"}`}
    </Badge>
  );
}
