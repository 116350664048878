import { useMemo, useState } from "react";
import ClientTable from "../../components/Table/ClientTable";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import LoadingIndicator from "../../components/LoadingIndicator";
import { format } from "date-fns";
import { VisitStatusTag } from "../pms/plan/visitStatusTag";
import { Box, Select, Tooltip } from "@chakra-ui/react";

// Admin Disindaki rollericin kullanilan tablo. Admin icin planIndexpage kullaniliyor.

export function VisitTable({ visits, lm }) {
  const { projectId } = useParams();
  const { data: personnelTypes } = useSWR(`/projects/${projectId}/personneltypes`);
  
  const [selectedLocation, setSelectedLocation] = useState("");

  const locations = useMemo(() => {
    if (!visits) return [];
    const uniqueLocations = [...new Set(visits.map(visit => visit.location.name))];
    return uniqueLocations.sort();
  }, [visits]);

  const filteredVisits = useMemo(() => {
    if (!selectedLocation || !visits) return visits;
    return visits.filter(visit => visit.location.name === selectedLocation);
  }, [visits, selectedLocation]);

  const columns = useMemo(
    () => [
      {
        Header: "Ziyaret Adı",
        accessor: "name",
        Cell: ({ cell }) => {
          const href = lm
            ? `lm/visits/${cell.row.original._id}`
            : `/ina/client/project/${projectId}/visits/${cell.row.original._id}`;
          const personnelNames = cell.row.original.personnelNames.map(p => {
            const personnelType = personnelTypes?.find(pt => pt._id === p.personnelType)?.name || p.personnelType;
            return `${p.name} (${personnelType})`;
          }).join('\n');
          return (
            <Tooltip label={<pre style={{ whiteSpace: 'pre-wrap' }}>{personnelNames}</pre>} hasArrow>
              <Link to={href}>{cell.row.values.name}</Link>
            </Tooltip>
          );
        },
      },
      {
        Header: "Lokasyon",
        accessor: "location.name",
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: ({ cell }) => (
          <>
            <VisitStatusTag status={cell.row.values.status} size={"sm"} />
          </>
        ),
      },
      
      {
        Header: "Performans",
        accessor: "visitPerformance",
        Cell: ({ cell }) => <>{"%" + cell.row.values.visitPerformance}</>,
      },

      {
        Header: "Ziyaret Tarihi",
        accessor: "visitDate",
        Cell: ({ cell }) => (
          <>
            {cell.row.values.visitDate
              ? format(new Date(cell.row.values.visitDate), "dd-MM-yyyy HH:mm")
              : "---"}{" "}
          </>
        ),
      },
    ],
    [personnelTypes]
  );

  return (
    <>
      <Box mb={4}>
        <Select
          placeholder="Tüm Lokasyonlar"
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          backgroundColor={"white"}
        >
          {locations.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </Select>
      </Box>
      {!visits ? (
        <LoadingIndicator />
      ) : (
        <ClientTable data={filteredVisits} columns={columns} />
      )}
    </>
  );
}
