import {
  Box,
  Text,
  Button,
  Flex,
  HStack,
  Heading,
  Tag,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { FiSave, FiSend, FiCamera } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import ConfirmAlert from "../../../components/Alert/ConfirmAlert";
import { useAuth } from "../../../contexts/AuthContext"; // Bu satırı ekleyin

import {
  PersonnelNameInputs,
  SurveyDate,
  SurveyImageModal,
  SurveyQuestionList,
} from "./";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import Card from "../../../components/Layout/Card";
export function MSSurvey({
  visitId,
  questionForm,
  answers,
  personnelData,
  visitDate,
  mutateQuestionForm,
}) {
  const { isAdmin } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirmed, setConfirm] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { showModal, showAlert } = useUiContext();
  const { personnelNames, personnelTypeLabels } = personnelData;
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      ...answers,
      personnelNames: { ...personnelNames },
      visitDate,
    },
    // { questionId: answer, ...,
    // personnelNames: {personnelType: name} }
  });

  const { surveyApi } = useApi();
  const toast = useToast();

  const onSave = async () => {
    setIsSaving(true);
    try {
      await surveyApi.sendSurvey({
        visitId,
        data: getValues(),
        action: "save",
      });
      if (isAdmin()) {
        navigate(-1); // Browser geçmişinde bir geriye git
      }
      toast({
        title: "Başarılı",
        description: "Anketi başarıyla kaydettiniz.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateQuestionForm();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsSaving(false);
  };

  const sendForApproval = async (data) => {
    setIsSubmitting(true);
    try {
      await surveyApi.sendSurvey({ visitId, data, action: "submit" });
      toast({
        title: "Başarılı",
        description: "Anketi başarıyla gönderdiniz.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateQuestionForm();
      navigate("/ina/ms/visits/pending");
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  const onSubmit = async (data) => {
    showAlert(
      <ConfirmAlert
        header="Anketi Gönder"
        body="Anketinizi yönetici onayına göndermek istiyor musunuz?"
        buttonLabel="Gönder"
        buttonColorScheme="pink"
        onAction={() => sendForApproval(data)}
      />
    );
  };

  async function addImage() {
    showModal(<SurveyImageModal visitId={visitId} />);
  }

  return (
    <PageLayout>
      <PageHeader title="Anket">
        <Button
          size="sm"
          colorScheme="blue"
          leftIcon={<FiCamera />}
          onClick={addImage}
        >
          Ziyaret Resimleri
        </Button>
      </PageHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonnelNameInputs
          personnelNames={personnelNames}
          personnelTypeLabels={personnelTypeLabels}
          register={register}
          errors={errors}
        />
        <SurveyDate control={control} errors={errors} width={"250px"} mb={4} />
        {questionForm.map((mainCat) => {
          return (
            <Card topMargin key={mainCat._id}>
              <Heading size={"md"} color={"blue.600"}>
                {mainCat.name}
              </Heading>
              <SurveyQuestionList
                questions={mainCat.questions}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
              />

              {mainCat.subCategories?.map((subCat) => (
                <Box key={subCat._id}>
                  <Text
                    fontSize={"lg"}
                    fontWeight={"bold"}
                    variant="solid"
                    colorScheme="gray"
                    ml={4}
                    mt={4}
                  >
                    {subCat.name}
                  </Text>
                  <SurveyQuestionList
                    questions={subCat.questions}
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                  />
                </Box>
              ))}
            </Card>
          );
        })}

        <br />

        {!isAdmin() && ( // Bu kontrol bloğunu ekleyin
          <>
            <Checkbox onChange={(e) => setConfirm(e.target.checked)}>
              <Text fontSize="sm">
                Anketi onaya göndermek için onay kutusunu işaretleyin
              </Text>
            </Checkbox>

            <Flex
              direction={["column", "row"]}
              mt={8}
              align={"start"}
              justify={"space-between"}
            >
              <HStack>
                <Button
                  colorScheme={"yellow"}
                  onClick={onSave}
                  isLoading={isSaving}
                  isDisabled={isSubmitting}
                  leftIcon={<FiSave />}
                >
                  Kaydet
                </Button>
                <Button
                  type="submit"
                  disabled={!isConfirmed}
                  colorScheme="pink"
                  isLoading={isSubmitting}
                  isDisabled={isSaving}
                  leftIcon={<FiSend />}
                >
                  Kaydet ve Onaya Gönder
                </Button>
              </HStack>
            </Flex>
          </>
        )}

        {isAdmin() && (
          <Button
            colorScheme={"yellow"}
            onClick={onSave}
            isLoading={isSaving}
            isDisabled={isSubmitting}
            leftIcon={<FiSave />}
          >
            Kaydet
          </Button>
        )}
      </form>
    </PageLayout>
  );
}
