import {
  Button,
  FormErrorMessage,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import { Logo } from "../../components/Svgs";
import Card from "../../components/Layout/Card";
import { regexEmail } from "../../util/regex";

export function LoginPage() {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await login(data);
      navigate("/ina");
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: error.response.data.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isAuthenticated()) {
    return <Navigate to="/ina" />;
  }

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>

      

        <Stack spacing={4} w={"full"} maxW={"md"}>
        <Logo />
          <Heading fontSize={"xl"} color={"gray.600"}>Hesabınıza Giriş Yapin. </Heading>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Card p={4} mt={4}>
              <Stack spacing={4}>
                <FormControl isInvalid={errors.email}>
                  <FormLabel htmlFor="email">E-Posta</FormLabel>
                  <Input
                    id="email"
                    {...register("email", {
                      required: "Gerekli alan",
                      pattern: {
                        value: regexEmail,
                        message: "Geçersiz e-posta",
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.password}>
                  <FormLabel htmlFor="password">Şifre</FormLabel>
                  <Input
                    id="password"
                    type="password"
                    {...register("password", {
                      required: "Gerekli alan",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Card>

            <Button
              fontFamily={"heading"}
              mt={8}
              isLoading={isSubmitting}
              type="submit"
              w={"full"}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, red.400,pink.400)",
                boxShadow: "xl",
              }}
            >
              Giriş Yap
            </Button>

            <br/><br/>
            <span color={"gray.600"}>Ver 1.0.0 </span>

            {/* <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.500"}>Forgot password?</Link>
              </Stack> */}
            {/* </Stack> */}
          </form>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={"Login Image"}
          objectFit={"cover"}
          src={
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
          }
        />
      </Flex>
    </Stack>
  );
}
