import useSWR from "swr";
import { useState, useMemo } from "react";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import { useParams } from "react-router";
import Card from "../../components/Layout/Card";
import { format } from "date-fns";
import {IconButton} from "@chakra-ui/react";
import ClientTable from "../../components/Table/ClientTable";
import {
  ChevronRightIcon
} from "@chakra-ui/icons";

import LoadingIndicator from "../../components/LoadingIndicator";
export function PersonnelReportPage() {
  const { projectId } = useParams();
  const columns = useMemo(
    () => [
      {
        Header: "Lokasyon",
        accessor: "locationName",
      },  {
        Header: "POZİSYON",
        accessor: "personnelTypeName",
      },
     
      {
        Header: "ZİYARET TARİHİ",
        accessor: "visitDate",
        Cell: ({ cell }) => (
          <>
            {cell.row.values.visitDate
              ? format(new Date(cell.row.values.visitDate), "dd-MM-yyyy HH:mm")
              : "---"}{" "}
          </>
        ),
      },
      {
        Header: "Personel",
        accessor: "personnelName",
      },
      {
        Header: "Max Puan",
        accessor: "maxPersonnelScore",
      },
      {
        Header: "Puan",
        accessor: "personnelScore",
      }, {
        Header: "Performans",
        accessor: "performance",
        Cell: ({ cell }) => <>{"%" + cell.row.values.performance}</>,
      },
      {
        Header: "",
        accessor: "visitId",
        Cell: ({ cell }) => (
          <IconButton 
            onClick={() => window.location.href = `http://localhost:3000/ina/client/project/${projectId}/visits/${cell.row.values.visitId}`}
           icon={<ChevronRightIcon />} />
          
        ),
      },
     
     
      
    ],
    []
  );
  const { data: reportData } = useSWR(`/project-personnel-report/${projectId}`);
  console.log(reportData)
  return (
    <>
     <PageLayout big>
     <Card mt={12}>
     <PageHeader inBox title="Personel Raporu" />

     
        {!reportData ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={reportData} columns={columns} fileName="PersonelRaporu"/>
          
        )}
      </Card>  
    </PageLayout>

    
    </>
  );
}
